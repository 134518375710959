<template>
  <div style="width: 100%">
    <ckeditor
        @ready="(editor) => eventReadyEditor(editor, this)"
        :editor="editor"
        :config="editorConfig"
        v-model="inputVal"
        :disabled="disabled"
        class="editorOnlyText"
    ></ckeditor>
  </div>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import EssentialsPlugin from "@ckeditor/ckeditor5-essentials/src/essentials";
import ParagraphPlugin from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import BoldPlugin from "@ckeditor/ckeditor5-basic-styles/src/bold";
import ItalicPlugin from "@ckeditor/ckeditor5-basic-styles/src/italic";
import Underline from "@ckeditor/ckeditor5-basic-styles/src/underline";

export default {
  name: "EditorOnlyText",
  props: {
    value: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    minHeight: {
      type: [Number, String],
      default: 50,
    }
  },
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
        plugins: [
          EssentialsPlugin,
          ParagraphPlugin,
          BoldPlugin,
          ItalicPlugin,
          Underline,
        ],
        toolbar:{
          items: ["heading", "|", "bold", "italic", "underline",],
          shouldNotGroupWhenFull: true,
        },

      }
    }
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    eventReadyEditor(editor, vm) {
      this.customHeight(editor, vm.minHeight);
    },
    customHeight: (editor, minHeight) => {
      let cpEditor = editor;
      editor.editing.view.change((writer) => {
        writer.setStyle( 'min-height', minHeight+'px', cpEditor.editing.view.document.getRoot() );
      });
    }
  }
}
</script>

<style>
</style>