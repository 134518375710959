<template>
  <div style="width: 100%" class="mb-6">
    <v-row class="ml-1">
      <v-col cols="12" class="pb-0">
        <p class="text-body-1 red--text font-weight-medium">Lưu ý: hiện tại mỗi ô nhập chỉ cho phép 1 loại dữ liệu (text/ ảnh/ audio), vui lòng không nhập 2 loại dữ liệu vào cùng 1 ô. Hệ thống sẽ không cover hiển thị cho trường hợp này!</p>
      </v-col>

      <v-col cols="6" class="pb-0">
        <v-row>
          <v-col cols="3" class="p-0">
            <span class="black--text font-weight-bold text-h6 float-right mr-2 mt-2">Options Cột A: </span>
          </v-col>
          <v-col cols="7" class="p-0">
            <v-select
                v-model="typeContentColumnA"
                :items="option_columnA_types"
                item-text="label"
                item-value="value"
                placeholder="Chọn loại dữ liệu"
                outlined dense
            ></v-select>
          </v-col>
          <v-col cols="12" class="py-0 px-8" v-if="typeContentColumnA === question_content_type_constant.IMAGE">
            <p class="text-body-1 grey--text lighten-1 font-weight-medium">Cần nhập ảnh có tỷ lệ dài:rộng là 1:1 (ảnh vuông), định dạng PNG/JPG, mỗi ô chỉ nhập 1 ảnh. Dung lượng ảnh không quá 2MB</p>
          </v-col>
        </v-row>
        <template v-for="(data_column, index) in dataOptionColumnA">
          <v-row :key="index">
            <v-col cols="1">
              <span class="black--text font-weight-bold text-h6 float-right mt-5">{{index+1}}.</span>
            </v-col>
            <v-col cols="9">
              <template v-if="typeContentColumnA === question_content_type_constant.TEXT">
                <EditorOnlyText placeholder="Nhập nôi dung" v-model="data_column.text" />
              </template>
              <template v-if="typeContentColumnA === question_content_type_constant.IMAGE">
                <div style="width: 100%" class="d-flex">
                  <v-file-input
                      outlined dense
                      class="mt-0"
                      prepend-icon="mdi-image"
                      accept="image/png, image/jpeg, image/jpg"
                      :label="'Chọn ảnh cho option ' + (index + 1) + ' cột A'"
                      @change="(e) => onImageFileOptionChange(e, index, 'A')"
                  ></v-file-input>
                </div>
                <v-img
                    v-if="data_column.image_data"
                    :src="data_column.image_data"
                    max-width="100%" max-height="200" class="mb-2"
                    contain
                />
              </template>
              <template v-if="typeContentColumnA === question_content_type_constant.AUDIO">
                <div style="width: 100%" class="d-flex">
                  <v-file-input
                      outlined dense
                      class="mt-0"
                      prepend-icon="mdi-music-note"
                      accept="audio/*"
                      :label="'Chọn audio cho option ' + (index + 1) + ' cột A'"
                      @change="(e) => onAudioFileOptionChange(e, index, 'A')"
                  ></v-file-input>
                </div>
                <vuetify-audio
                    v-if="data_column.audio_data"
                    :file="data_column.audio_data"
                    color="primary" class="mb-6"
                />
              </template>
            </v-col>
            <v-col cols="2" class="pl-0">
              <v-hover
                  v-slot="{ hover }"
              >
                <v-icon
                    @click="deleteItemColumnA(index)"
                    class="mt-5 cursor-pointer" :color="hover ? 'red' : 'grey' ">
                  mdi-delete
                </v-icon>
              </v-hover>
            </v-col>
          </v-row>
        </template>
        <v-row>
          <v-col cols="12">
            <v-btn large @click="btnAddItemToColumnA()">
              <v-icon>mdi-plus</v-icon> Add Option A
            </v-btn>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="6" class="pb-0">
        <v-row>
          <v-col cols="3" class="p-0">
            <span class="black--text font-weight-bold text-h6 float-right mr-2 mt-2">Options Cột B: </span>
          </v-col>
          <v-col cols="7" class="p-0">
            <v-select
                v-model="typeContentColumnB"
                :items="option_columnB_types"
                item-text="label"
                item-value="value"
                placeholder="Chọn loại dữ liệu"
                outlined dense
            ></v-select>
          </v-col>
        </v-row>
        <template v-for="(data_column, index) in dataOptionColumnB">
          <v-row :key="index">
            <v-col cols="1">
              <!--              <span class="black&#45;&#45;text font-weight-bold text-h6 float-right mt-5">{{index+1}}.</span>-->
              <span class="black--text font-weight-bold text-h6 float-right mt-5">{{index|getAlphabetCharacterFromIndex}}.</span>
            </v-col>
            <v-col cols="9">
              <template v-if="typeContentColumnB === question_content_type_constant.TEXT">
                <EditorOnlyText placeholder="Nhập nôi dung" v-model="data_column.text" />
              </template>
              <template v-if="typeContentColumnB === question_content_type_constant.IMAGE">
                <div style="width: 100%" class="d-flex">
                  <v-file-input
                      outlined dense
                      class="mt-0"
                      prepend-icon="mdi-image"
                      accept="image/png, image/jpeg, image/jpg"
                      :label="'Chọn ảnh cho option ' + (index + 1) + ' cột B'"
                      @change="(e) => onImageFileOptionChange(e, index, 'B')"
                  ></v-file-input>
                </div>
                <v-img
                    v-if="data_column.image_data"
                    :src="data_column.image_data"
                    max-width="100%" max-height="200" class="mb-2"
                    contain
                />
              </template>
              <template v-if="typeContentColumnB === question_content_type_constant.AUDIO">
                <div style="width: 100%" class="d-flex">
                  <v-file-input
                      outlined dense
                      class="mt-0"
                      prepend-icon="mdi-music-note"
                      accept="audio/*"
                      :label="'Chọn audio cho option ' + (index + 1) + ' cột B'"
                      @change="(e) => onAudioFileOptionChange(e, index, 'B')"
                  ></v-file-input>
                </div>
                <vuetify-audio
                    v-if="data_column.audio_data"
                    :file="data_column.audio_data"
                    color="primary" class="mb-6"
                />
              </template>
            </v-col>
            <v-col cols="2" class="pl-0">
              <v-hover
                  v-slot="{ hover }"
              >
                <v-icon
                    @click="deleteItemColumnB(index)"
                    class="mt-5 cursor-pointer" :color="hover ? 'red' : 'grey' ">
                  mdi-delete
                </v-icon>
              </v-hover>
            </v-col>
          </v-row>
        </template>
        <v-row>
          <v-col cols="12">
            <v-btn large @click="btnAddItemToOptionColumnB()">
              <v-icon>mdi-plus</v-icon> Add Option B
            </v-btn>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="8" class="pb-0">
        <p class="black--text font-weight-bold text-h6 mt-4 mb-1">Đáp án </p>
        <p class="text-body-1 grey--text lighten-1 font-weight-medium">Chọn 1 option của cột B tương ứng là đáp án cho mỗi option ở cột A</p>

        <template v-for="(correct_obj, indexCorrect) in dataCorrectProp">
          <v-row :key="indexCorrect">
            <v-col cols="3" class="">
              <p class="black--text font-weight-medium text-h6 mt-2 mb-1">Option {{indexCorrect+1}} cột A</p>
            </v-col>
            <v-col cols="1">
              <p class="black--text font-weight-bold text-h6 light-blue--text mt-2 mb-1">-</p>
            </v-col>
            <v-col cols="8" class="">
              <v-select
                  @input="(event) => eventChosenCorrectForColumnA(event, indexCorrect)"
                  :value="correct_obj.columnBOffset"
                  :items="dataOptionColumnB.map((option, index) => {
                    return {
                      label: 'Option ' + (getAlphabetCharacterFromIndex(index)) + ' cột B',
                      index_display: index,
                      value: option.offset,
                      correct_obj: correct_obj,
                    }
                  })"
                  item-text="label"
                  item-value="value"
                  outlined dense clearable
                  placeholder="Chọn đáp án cho cột A"
              >
                <template v-slot:item="{item}">
                  <span class="black--text">{{item.label}}</span>
                </template>
                <template v-slot:selection="{ item, index }">
                  <template v-if="item.correct_obj.columnBOffset">
                    <span class="black--text">Option {{item.index_display | getAlphabetCharacterFromIndex }} cột B</span>
                  </template>
                  <template v-else>
                    <span class="grey--text">Chọn đáp án cho cột A</span>
                  </template>
                </template>
              </v-select>
            </v-col>
          </v-row>
        </template>
      </v-col>

    </v-row>
  </div>
</template>

<script>
import EditorOnlyText from "../../../../../components/ckeditor5/EditorOnlyText";
import constantTestBuilder from "../../mixins/Support/constantTestBuilder";
import VuetifyAudio from "../../../marking-management/Audio";

export default {
  name: "MatchingCard",
  components: {
    EditorOnlyText,
    VuetifyAudio
  },
  mixins: [
      constantTestBuilder,
  ],
  props: {
    dataOptionColumnAProp: {
      type: Array,
      default: () => [
        {
          uuid: null,
          text: "",
          image_data: null,
          image_file: null,
          audio_data: null,
          audio_file: null,
          offset: 1,
        }
      ],
    },
    typeContentColumnAProp: {
      type: Number,
      default: 1, // this.question_content_type_constant.TEXT
    },
    dataOptionColumnBProp: {
      type: Array,
      default: () => [
        {
          uuid: null,
          text: "",
          image_data: null,
          image_file: null,
          audio_data: null,
          audio_file: null,
          offset: 1,
        }
      ],
    },
    typeContentColumnBProp: {
      type: Number,
      default: 1, // this.question_content_type_constant.TEXT
    },
    dataCorrectProp: {
      type: Array,
      default: () => [
          { columnAUuid: null, columnBUuid: null, columnAOffset: 1, columnBOffset: null,},
          { columnAUuid: null, columnBUuid: null, columnAOffset: 2, columnBOffset: null,},
      ],
    },
  },
  data() {
    return {};
  },
  computed: {
    option_columnA_types: function () {
      return [
        { label: "Text", value: this.question_content_type_constant.TEXT },
        { label: "Image", value: this.question_content_type_constant.IMAGE },
        { label: "Audio", value: this.question_content_type_constant.AUDIO },
      ];
    },
    option_columnB_types: function () {
      return [
        { label: "Text", value: this.question_content_type_constant.TEXT },
        { label: "Image", value: this.question_content_type_constant.IMAGE },
        { label: "Audio", value: this.question_content_type_constant.AUDIO },
      ];
    },
    dataOptionColumnA: {
      get() {
        return this.dataOptionColumnAProp;
      },
      set(value) {
        this.$emit("setDataOptionColumnA", value);
      },
    },
    typeContentColumnA: {
      get() {
        return this.typeContentColumnAProp;
      },
      set(value) {
        this.$emit("setTypeContentColumnA", value);
      },
    },
    dataOptionColumnB: {
      get() {
        return this.dataOptionColumnBProp;
      },
      set(value) {
        this.$emit("setDataOptionColumnB", value);
      },
    },
    typeContentColumnB: {
      get() {
        return this.typeContentColumnBProp;
      },
      set(value) {
        this.$emit("setTypeContentColumnB", value);
      },
    },
    dataCorrect: {
      get() {
        return this.dataCorrectProp;
      },
      set(value) {
        this.$emit("setDataCorrect", value);
      },
    },

  },
  methods: {
    getAlphabetCharacterFromIndex(index) {
      return String.fromCharCode(65 + index);
    },
    messError(mess) {
      this.$toasted.error(mess, {theme: "toasted-primary", position: "top-right", duration: 4000,});
    },
    btnAddItemToColumnA() {
      if (this.dataOptionColumnA.length >= 5) {
        this.messError('Chỉ cho phép tối đa 5 option cột A');
        return
      }
      if (this.dataOptionColumnA.length === this.dataOptionColumnB.length) {
        this.addItemOptionToColumnA();
        if (!(this.dataOptionColumnB.length  >= 5)) {
          this.addItemOptionToColumnB();
        }
      } else {
        this.addItemOptionToColumnA();
      }
    },
    addItemOptionToColumnA() {
      let newOffset = this.dataOptionColumnA[this.dataOptionColumnA.length -1].offset + 1;
      let objColumn = {
        offset: newOffset,
        uuid: null,
        text: "",
        image_data: null,
        image_file: null,
        audio_data: null,
        audio_file: null,
      }
      this.dataOptionColumnA.push(objColumn);
      this.dataCorrect.push({ columnAUuid: null, columnBUuid: null, columnAOffset: newOffset, columnBOffset: null,});
      return objColumn;
    },
    deleteItemColumnA(index) {
      let minQuestion = 2;
      if (this.dataOptionColumnA.length <= minQuestion) {
        this.messError('Phải có tối thiểu '+minQuestion+' option cột A');
        return
      }
      this.dataOptionColumnA.splice(index, 1);
      this.dataCorrect.splice(index, 1);
    },
    btnAddItemToOptionColumnB() {
      if (this.dataOptionColumnB.length >= 5) {
        this.messError('Chỉ cho phép tối đa 5 option cột B');
        return
      }
      if (this.dataOptionColumnA.length === this.dataOptionColumnB.length) {
        this.addItemOptionToColumnB();
        if (!(this.dataOptionColumnA.length  >= 5)) {
          this.addItemOptionToColumnA();
        }
      } else {
        this.addItemOptionToColumnB();
      }
    },
    addItemOptionToColumnB() {
      let objColumn = {
        offset: this.dataOptionColumnB[this.dataOptionColumnB.length -1].offset + 1,
        uuid: null,
        text: "",
        image_data: null,
        image_file: null,
        audio_data: null,
        audio_file: null,
      }
      this.dataOptionColumnB.push(objColumn);
      return objColumn;
    },
    deleteItemColumnB(index) {
      let minOption = 2;
      if (this.dataOptionColumnB.length <= minOption) {
        this.messError('Phải có tối thiểu '+minOption+' option cột B');
        return
      }
      this.dataOptionColumnB.splice(index, 1);
    },
    onImageFileOptionChange(file, index, column = 'A') {
      let dataOption = column === 'A' ? this.dataOptionColumnA : this.dataOptionColumnB;

      if (file === undefined || file == null) {
        dataOption[index].image_data = null;
        dataOption[index].image_file = null;
        return;
      }
      let reader = new FileReader();
      reader.onload = (e) => {
        dataOption[index].image_data = e.target.result;
        dataOption[index].image_file = file;
      };
      reader.readAsDataURL(file);
    },
    onAudioFileOptionChange(file, index, column = 'A') {
      let dataOption = column === 'A' ? this.dataOptionColumnA : this.dataOptionColumnB;

      if (file === undefined || file == null) {
        dataOption[index].audio_data = null;
        dataOption[index].audio_file = null;
        return;
      }
      let reader = new FileReader();
      reader.onload = (e) => {
        dataOption[index].audio_data = e.target.result;
        dataOption[index].audio_file = file;
      };
      reader.readAsDataURL(file);
    },
    eventChosenCorrectForColumnA(event, indexCorrect) {
      console.log(event, indexCorrect);
      let vm = this;
      if (!event) {
        vm.dataCorrect[indexCorrect].columnBOffset = null;
        return;
      }
      if (isDuplicateCorrect()) {
        vm.messError('Đáp án này đã được chọn cho 1 option khác');
        vm.dataCorrect[indexCorrect].columnBOffset = null;
        return;
      }
      let objColumnB = vm.dataOptionColumnB.find((option) => option.offset === event);
      objColumnB = JSON.parse(JSON.stringify(objColumnB));
      this.dataCorrect[indexCorrect].columnBOffset = objColumnB.offset;
      this.dataCorrect[indexCorrect].columnBUuid = objColumnB.uuid;


      function isDuplicateCorrect() {
        let count = 0;
        for (let i = 0; i < vm.dataCorrect.length; i++) {
          if (vm.dataCorrect[i].columnBOffset === event) {
            count++;
          }
        }
        return count > 0;
      }
    },
  },
}
</script>

<style scoped>

</style>